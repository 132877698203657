<template>
  <div class="layout">
    <HeaderControllerSkeleton />
    <FeedControllerSkeleton />
    <main class="layout__main">
      <MainPageLayoutControllerSkeleton />
      <slot></slot>

      <MainPageFreeRewards v-if="!hideFreeCasesBanner" icon="SvgoFreeRewardIcon" :redirect-to="useLinks().FREE_CASES" />
      <ClientOnly>
        <CallToActionController v-if="showBottomSections" />
      </ClientOnly>
    </main>
    <StatisticControllerSkeleton>
      <StatisticController
        :keys-show="[
          EStatisticItemVariants.OPENED,
          EStatisticItemVariants.USERS,
          EStatisticItemVariants.UPGRADES,
          EStatisticItemVariants.BATTLES,
          EStatisticItemVariants.ONLINE,
          EStatisticItemVariants.ARCANES,
        ]"
      />
    </StatisticControllerSkeleton>

    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
import CallToActionController from '~/features/mainPage/controllers/CallToActionController/CallToActionController.vue';
import { EStatisticItemVariants } from '~/config/apps/index.types';

const { hideFreeCasesBanner } = useProjectSettings();

const route = useRoute();

const showBottomSections = computed(() => !route.meta.hideCallToAction);
</script>

<style lang="scss" scoped src="assets/layouts/default.scss"></style>
