import { default as IFrameControllerd7RJ4XI8cIMeta } from "/app/features/bk/controllers/IFrameController/IFrameController.vue?macro=true";
import { default as IFrameTestController2jZinoTdeQMeta } from "/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue?macro=true";
import { default as DropPage6YbOG0oFFzMeta } from "/app/project-layers/dota/routes/cases/DropPage/DropPage.vue?macro=true";
import { default as OpenPageF1XkM3FkW2Meta } from "/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue?macro=true";
import { default as TestSimpleCaseyoRmjU7Nq4Meta } from "/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue?macro=true";
import { default as DailyCasesEWF9Qfl9xKMeta } from "/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue?macro=true";
import { default as MainPagew72xjUTr5nMeta } from "/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue?macro=true";
import { default as MainPage06SJVY7WhuMeta } from "/app/project-layers/dota/routes/payment/MainPage/MainPage.vue?macro=true";
import { default as ResultPageyN65YeFz0dMeta } from "/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue?macro=true";
import { default as UnauthPage8R7LuCqveGMeta } from "/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue?macro=true";
import { default as CreateBattlePagekmi9q7vc2FMeta } from "/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue?macro=true";
import { default as MainPagePvBaHM5neOMeta } from "/app/project-layers/dota/routes/battles/MainPage/MainPage.vue?macro=true";
import { default as HistoryPageb3B9yN2TK0Meta } from "/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue?macro=true";
import { default as PersonalStatisticsPageQsVhBX3Uk3Meta } from "/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue?macro=true";
import { default as RunningBattlePageAbGeUvBQdAMeta } from "/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue?macro=true";
import { default as NotWorkingBattlePageXQZXbTVt5rMeta } from "/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue?macro=true";
import { default as MainPageTIgrRkITzqMeta } from "/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue?macro=true";
import { default as EventCasesPage4UPX5kDU6eMeta } from "/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue?macro=true";
import { default as TopLaddersPagehmTTvA4r3FMeta } from "/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue?macro=true";
import { default as MainPagessdYV5fTaKMeta } from "/app/project-layers/dota/routes/techies/MainPage/MainPage.vue?macro=true";
import { default as UserPageHxUMst9TLaMeta } from "/app/project-layers/dota/routes/profile/UserPage/UserPage.vue?macro=true";
import { default as MainPageG30m3bIW7WMeta } from "/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue?macro=true";
import { default as MainPagePC80xvdxQ7Meta } from "/app/project-layers/dota/routes/faq/MainPage/MainPage.vue?macro=true";
import { default as Landinghm15ystElzMeta } from "/app/project-layers/dota/routes/hamster/Landing/Landing.vue?macro=true";
import { default as MainPagemXSBXhXkshMeta } from "/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue?macro=true";
import { default as InstructionsPagemZd1fVkYsYMeta } from "/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue?macro=true";
import { default as SettingsPageIDYxL3X6aGMeta } from "/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue?macro=true";
import { default as CheckPageBoYfhNF16jMeta } from "/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue?macro=true";
import { default as CaseHistoryPageTEQind93kdMeta } from "/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue?macro=true";
import { default as EventConditionPageAGBKs3VZMmMeta } from "/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue?macro=true";
import { default as PrivacyPolicyPageMegrTl59MVMeta } from "/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue?macro=true";
import { default as CookiesPage4DTAz0OqHmMeta } from "/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue?macro=true";
import { default as ContactsPaget3DS91mmMQMeta } from "/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue?macro=true";
import { default as UserAgreementPageEDSUHDdl9sMeta } from "/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue?macro=true";
import { default as supersecretdailyg71AqbO3lGMeta } from "/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue?macro=true";
import { default as MainPage7XZtU3CEmXMeta } from "/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue?macro=true";
import { default as MainPageeEGLxnmJTKMeta } from "/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue?macro=true";
export default [
  {
    name: "iframe-data___en",
    path: "/en/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___ru",
    path: "/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___kk",
    path: "/kk/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___az",
    path: "/az/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___uz",
    path: "/uz/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___es",
    path: "/es/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___hi",
    path: "/hi/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___ph",
    path: "/ph/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___id",
    path: "/id/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-testing___en",
    path: "/en/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___ru",
    path: "/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___kk",
    path: "/kk/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___az",
    path: "/az/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___uz",
    path: "/uz/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___es",
    path: "/es/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___hi",
    path: "/hi/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___ph",
    path: "/ph/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___id",
    path: "/id/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "drop-page___en",
    path: "/en/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___ru",
    path: "/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___kk",
    path: "/kk/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___az",
    path: "/az/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___uz",
    path: "/uz/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___es",
    path: "/es/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___hi",
    path: "/hi/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___ph",
    path: "/ph/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___id",
    path: "/id/open/:caseName/:gameId",
    component: () => import("/app/project-layers/dota/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "open-page___en",
    path: "/en/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___ru",
    path: "/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___kk",
    path: "/kk/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___az",
    path: "/az/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___uz",
    path: "/uz/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___es",
    path: "/es/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___hi",
    path: "/hi/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___ph",
    path: "/ph/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "open-page___id",
    path: "/id/open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/OpenPageDev/OpenPage.vue")
  },
  {
    name: "test-open-page___en",
    path: "/en/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___ru",
    path: "/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___kk",
    path: "/kk/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___az",
    path: "/az/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___uz",
    path: "/uz/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___es",
    path: "/es/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___hi",
    path: "/hi/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___ph",
    path: "/ph/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "test-open-page___id",
    path: "/id/test-open/:caseName",
    component: () => import("/app/project-layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue")
  },
  {
    name: "daily-cases___en",
    path: "/en/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___ru",
    path: "/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___kk",
    path: "/kk/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___az",
    path: "/az/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___uz",
    path: "/uz/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___es",
    path: "/es/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___hi",
    path: "/hi/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___ph",
    path: "/ph/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___id",
    path: "/id/daily_cases",
    component: () => import("/app/project-layers/dota/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "cardform___en",
    path: "/en/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___ru",
    path: "/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___kk",
    path: "/kk/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___az",
    path: "/az/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___uz",
    path: "/uz/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___es",
    path: "/es/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___hi",
    path: "/hi/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___ph",
    path: "/ph/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "cardform___id",
    path: "/id/cardform",
    meta: MainPagew72xjUTr5nMeta || {},
    component: () => import("/app/project-layers/dota/routes/cardForm/MainPage/MainPage.vue")
  },
  {
    name: "payment___en",
    path: "/en/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___ru",
    path: "/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___kk",
    path: "/kk/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___az",
    path: "/az/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___uz",
    path: "/uz/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___es",
    path: "/es/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___hi",
    path: "/hi/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___ph",
    path: "/ph/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___id",
    path: "/id/refill",
    meta: MainPage06SJVY7WhuMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment-result___en",
    path: "/en/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___ru",
    path: "/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___kk",
    path: "/kk/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___az",
    path: "/az/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___uz",
    path: "/uz/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___es",
    path: "/es/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___hi",
    path: "/hi/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___ph",
    path: "/ph/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___id",
    path: "/id/refill/result",
    meta: ResultPageyN65YeFz0dMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-unauth___en",
    path: "/en/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___ru",
    path: "/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___kk",
    path: "/kk/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___az",
    path: "/az/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___uz",
    path: "/uz/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___es",
    path: "/es/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___hi",
    path: "/hi/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___ph",
    path: "/ph/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___id",
    path: "/id/refill/unauth",
    meta: UnauthPage8R7LuCqveGMeta || {},
    component: () => import("/app/project-layers/dota/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "create-battle-page___en",
    path: "/en/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___ru",
    path: "/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___kk",
    path: "/kk/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___az",
    path: "/az/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___uz",
    path: "/uz/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___es",
    path: "/es/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___hi",
    path: "/hi/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___ph",
    path: "/ph/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "create-battle-page___id",
    path: "/id/battles/create",
    meta: { ...(CreateBattlePagekmi9q7vc2FMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue")
  },
  {
    name: "main-battles-page___en",
    path: "/en/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___ru",
    path: "/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___kk",
    path: "/kk/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___az",
    path: "/az/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___uz",
    path: "/uz/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___es",
    path: "/es/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___hi",
    path: "/hi/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___ph",
    path: "/ph/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "main-battles-page___id",
    path: "/id/battles",
    meta: MainPagePvBaHM5neOMeta || {},
    component: () => import("/app/project-layers/dota/routes/battles/MainPage/MainPage.vue")
  },
  {
    name: "history-battles-page___en",
    path: "/en/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___ru",
    path: "/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___kk",
    path: "/kk/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___az",
    path: "/az/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___uz",
    path: "/uz/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___es",
    path: "/es/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___hi",
    path: "/hi/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___ph",
    path: "/ph/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "history-battles-page___id",
    path: "/id/battles/history",
    meta: HistoryPageb3B9yN2TK0Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/HistoryPage/HistoryPage.vue")
  },
  {
    name: "statistics-battles-page___en",
    path: "/en/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___ru",
    path: "/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___kk",
    path: "/kk/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___az",
    path: "/az/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___uz",
    path: "/uz/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___es",
    path: "/es/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___hi",
    path: "/hi/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___ph",
    path: "/ph/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "statistics-battles-page___id",
    path: "/id/battles/statistics",
    meta: PersonalStatisticsPageQsVhBX3Uk3Meta || {},
    component: () => import("/app/project-layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue")
  },
  {
    name: "single-battle-page___en",
    path: "/en/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___ru",
    path: "/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___kk",
    path: "/kk/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___az",
    path: "/az/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___uz",
    path: "/uz/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___es",
    path: "/es/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___hi",
    path: "/hi/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___ph",
    path: "/ph/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "single-battle-page___id",
    path: "/id/battles/:battleId",
    meta: { ...(RunningBattlePageAbGeUvBQdAMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue")
  },
  {
    name: "not-working-battle-page___en",
    path: "/en/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___ru",
    path: "/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___kk",
    path: "/kk/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___az",
    path: "/az/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___uz",
    path: "/uz/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___es",
    path: "/es/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___hi",
    path: "/hi/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___ph",
    path: "/ph/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "not-working-battle-page___id",
    path: "/id/battles/temp",
    meta: { ...(NotWorkingBattlePageXQZXbTVt5rMeta || {}), ...{"footer":false} },
    component: () => import("/app/project-layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue")
  },
  {
    name: "main-page___en",
    path: "/en",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___ru",
    path: "/",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___kk",
    path: "/kk",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___az",
    path: "/az",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___uz",
    path: "/uz",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___es",
    path: "/es",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___hi",
    path: "/hi",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___ph",
    path: "/ph",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___id",
    path: "/id",
    meta: MainPageTIgrRkITzqMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "new-cases___en",
    path: "/en/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___ru",
    path: "/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___kk",
    path: "/kk/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___az",
    path: "/az/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___uz",
    path: "/uz/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___es",
    path: "/es/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___hi",
    path: "/hi/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___ph",
    path: "/ph/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___id",
    path: "/id/new-cases",
    meta: EventCasesPage4UPX5kDU6eMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "top___en",
    path: "/en/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___ru",
    path: "/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___kk",
    path: "/kk/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___az",
    path: "/az/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___uz",
    path: "/uz/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___es",
    path: "/es/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___hi",
    path: "/hi/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___ph",
    path: "/ph/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___id",
    path: "/id/top",
    meta: TopLaddersPagehmTTvA4r3FMeta || {},
    component: () => import("/app/project-layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "techies___en",
    path: "/en/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___ru",
    path: "/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___kk",
    path: "/kk/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___az",
    path: "/az/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___uz",
    path: "/uz/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___es",
    path: "/es/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___hi",
    path: "/hi/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___ph",
    path: "/ph/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "techies___id",
    path: "/id/techies",
    meta: MainPagessdYV5fTaKMeta || {},
    component: () => import("/app/project-layers/dota/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "profile___en",
    path: "/en/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___ru",
    path: "/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___kk",
    path: "/kk/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___az",
    path: "/az/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___uz",
    path: "/uz/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___es",
    path: "/es/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___hi",
    path: "/hi/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___ph",
    path: "/ph/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___id",
    path: "/id/profile/:userId",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___en",
    path: "/en/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___ru",
    path: "/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___kk",
    path: "/kk/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___az",
    path: "/az/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___uz",
    path: "/uz/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___es",
    path: "/es/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___hi",
    path: "/hi/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___ph",
    path: "/ph/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___id",
    path: "/id/profile",
    meta: UserPageHxUMst9TLaMeta || {},
    component: () => import("/app/project-layers/dota/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "quiz___en",
    path: "/en/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___ru",
    path: "/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___kk",
    path: "/kk/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___az",
    path: "/az/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___uz",
    path: "/uz/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___es",
    path: "/es/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___hi",
    path: "/hi/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___ph",
    path: "/ph/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "quiz___id",
    path: "/id/quiz",
    meta: MainPageG30m3bIW7WMeta || {},
    component: () => import("/app/project-layers/dota/routes/quiz/MainPage/MainPage.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___ru",
    path: "/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___kk",
    path: "/kk/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___az",
    path: "/az/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___uz",
    path: "/uz/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___hi",
    path: "/hi/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___ph",
    path: "/ph/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___id",
    path: "/id/faq",
    meta: MainPagePC80xvdxQ7Meta || {},
    component: () => import("/app/project-layers/dota/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "hamster___en",
    path: "/en/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___ru",
    path: "/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___kk",
    path: "/kk/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___az",
    path: "/az/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___uz",
    path: "/uz/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___es",
    path: "/es/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___hi",
    path: "/hi/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___ph",
    path: "/ph/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___id",
    path: "/id/hamster",
    meta: Landinghm15ystElzMeta || {},
    component: () => import("/app/project-layers/dota/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "secret-shop___en",
    path: "/en/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___ru",
    path: "/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___kk",
    path: "/kk/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___az",
    path: "/az/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___uz",
    path: "/uz/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___es",
    path: "/es/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___hi",
    path: "/hi/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___ph",
    path: "/ph/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___id",
    path: "/id/offersshop",
    meta: MainPagemXSBXhXkshMeta || {},
    component: () => import("/app/project-layers/dota/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "provably-fair-instructions___en",
    path: "/en/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___ru",
    path: "/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___kk",
    path: "/kk/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___az",
    path: "/az/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___uz",
    path: "/uz/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___es",
    path: "/es/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___hi",
    path: "/hi/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___ph",
    path: "/ph/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___id",
    path: "/id/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/dota/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-settings___en",
    path: "/en/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___ru",
    path: "/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___kk",
    path: "/kk/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___az",
    path: "/az/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___uz",
    path: "/uz/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___es",
    path: "/es/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___hi",
    path: "/hi/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___ph",
    path: "/ph/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___id",
    path: "/id/provably-fair/settings",
    meta: { ...(SettingsPageIDYxL3X6aGMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-check___en",
    path: "/en/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___ru",
    path: "/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___kk",
    path: "/kk/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___az",
    path: "/az/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___uz",
    path: "/uz/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___es",
    path: "/es/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___hi",
    path: "/hi/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___ph",
    path: "/ph/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___id",
    path: "/id/provably-fair/check",
    meta: { ...(CheckPageBoYfhNF16jMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/dota/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-case-history___en",
    path: "/en/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___ru",
    path: "/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___kk",
    path: "/kk/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___az",
    path: "/az/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___uz",
    path: "/uz/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___es",
    path: "/es/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___hi",
    path: "/hi/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___ph",
    path: "/ph/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___id",
    path: "/id/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/dota/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "event-condition___en",
    path: "/en/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___ru",
    path: "/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___kk",
    path: "/kk/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___az",
    path: "/az/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___uz",
    path: "/uz/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___es",
    path: "/es/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___hi",
    path: "/hi/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___ph",
    path: "/ph/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___id",
    path: "/id/eventCondition",
    meta: { ...(EventConditionPageAGBKs3VZMmMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___ru",
    path: "/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___kk",
    path: "/kk/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___az",
    path: "/az/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___uz",
    path: "/uz/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___hi",
    path: "/hi/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___ph",
    path: "/ph/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___id",
    path: "/id/privacy-policy",
    meta: { ...(PrivacyPolicyPageMegrTl59MVMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/dota/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "cookies___en",
    path: "/en/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___ru",
    path: "/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___kk",
    path: "/kk/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___az",
    path: "/az/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___uz",
    path: "/uz/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___es",
    path: "/es/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___hi",
    path: "/hi/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___ph",
    path: "/ph/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___id",
    path: "/id/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "contacts___en",
    path: "/en/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___ru",
    path: "/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___kk",
    path: "/kk/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___az",
    path: "/az/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___uz",
    path: "/uz/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___es",
    path: "/es/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___hi",
    path: "/hi/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___ph",
    path: "/ph/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___id",
    path: "/id/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "user-agreement___en",
    path: "/en/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___ru",
    path: "/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___kk",
    path: "/kk/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___az",
    path: "/az/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___uz",
    path: "/uz/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___es",
    path: "/es/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___hi",
    path: "/hi/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___ph",
    path: "/ph/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___id",
    path: "/id/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/dota/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "supersecretdaily___en",
    path: "/en/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___ru",
    path: "/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___kk",
    path: "/kk/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___az",
    path: "/az/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___uz",
    path: "/uz/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___es",
    path: "/es/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___hi",
    path: "/hi/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___ph",
    path: "/ph/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "supersecretdaily___id",
    path: "/id/supersecretdaily",
    component: () => import("/app/project-layers/dota/routes/supersecretdaily/supersecretdaily.vue")
  },
  {
    name: "ui-kit___en",
    path: "/en/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___ru",
    path: "/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___kk",
    path: "/kk/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___az",
    path: "/az/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___uz",
    path: "/uz/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___es",
    path: "/es/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___hi",
    path: "/hi/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___ph",
    path: "/ph/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "ui-kit___id",
    path: "/id/ui-kit",
    component: () => import("/app/project-layers/dota/routes/ui-kit/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___en",
    path: "/en/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___ru",
    path: "/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___kk",
    path: "/kk/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___az",
    path: "/az/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___uz",
    path: "/uz/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___es",
    path: "/es/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___hi",
    path: "/hi/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___ph",
    path: "/ph/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___id",
    path: "/id/upgrades",
    component: () => import("/app/project-layers/dota/routes/upgrades/MainPage/MainPage.vue")
  }
]